export function register() {
    function showUpdateBanner(onConfirm) {
        const banner = document.createElement("div");
        banner.className = "alert alert-warning alert-dismissible fade position-fixed w-100 bottom-0 m-0 p-3";
        banner.role = "alert";
        banner.innerHTML = `<span><strong>New version available!</strong> Refresh to update.</span>
        <button id="reload-btn" className="btn btn-primary btn-sm">Reload</button>`;
        document.body.appendChild(banner);
        setTimeout(function () {
            banner.classList.add("show");
        }, 100);
        document.getElementById("reload-btn")
            .addEventListener("click", ()=>{
                onConfirm();
                banner.classList.remove("show");
                setTimeout(()=> window.location.reload(), 300);
            });
    }

    if ("serviceWorker" in navigator) {
        window.addEventListener("load", ()=>{
            navigator.serviceWorker
                .register("/service-worker.js")
                .then((registration) => {
                    registration.onupdatefound = () => {
                        const installingWorker = registration.installing;
                        if (installingWorker) {
                            installingWorker.onstatechange = () => {
                                if (installingWorker.state === "installed" && navigator.serviceWorker.controller) {
                                    showUpdateBanner(()=>{
                                        installingWorker.postMessage({type: "SKIP_WAITING"});
                                    });
                                }
                            };
                        }
                    }
                })
                .catch((error) => {
                    console.error("Service worker registration failed with error: ", error);
                })
        });
    }
    
}
